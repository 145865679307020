/* Algemene pagina styling */
.product-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* Algemene layout */
.page-layout {
  display: flex;
  gap: 20px;
}

/* Filters */
.filters {
  flex: 0 0 250px; /* Breedte van de filters */
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section h3 {
  margin-bottom: 10px;
}

.filter-section label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

/* Product Grid */
.product-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Responsieve styling */
@media (max-width: 768px) {
  .filters {
    display: none; /* Verberg filters op mobiele apparaten */
  }
}


/* Product Card */
.product-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.product-image {
  max-width: 100%;
  max-height: 165px;
  height: auto;
  margin-bottom: 10px;
}

.product-name {
  font-size: 1.2rem;
  margin: 10px 0;
}

.product-price {
  font-size: 1.1rem;
  color: #009eec;
  margin-bottom: 10px;
}

.add-to-cart {
  background-color: #009eec;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.add-to-cart:hover {
  background-color: #007bbd;
}
