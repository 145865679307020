/* Algemene pagina styling */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

/* Header Styling */
.header {
  background-color: #009eec;
  color: #fff;
  padding: 15px 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.nav-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.nav-menu a:hover {
  text-decoration: underline;
}

/* Main Content */
.main-content {
  padding: 20px;
  background-color: #f9f9f9;
  min-height: calc(100vh - 180px); /* Houd rekening met header en footer */
}

/* Footer Styling */
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 15px 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer a {
  color: #009eec;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
